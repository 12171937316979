// Generated by Framer (d03ec02)

import { addFonts, cx, CycleVariantState, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["rIX9M3ri_"];

const serializationHash = "framer-3qqCZ"

const variantClassNames = {rIX9M3ri_: "framer-v-juid59"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "rIX9M3ri_", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-juid59", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"rIX9M3ri_"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backgroundColor: "var(--token-fdc5bec5-ecce-4e15-ba3b-314d1581164d, rgb(242, 242, 248))", borderBottomLeftRadius: 32, borderBottomRightRadius: 32, borderTopLeftRadius: 32, borderTopRightRadius: 32, ...style}}/></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-3qqCZ[data-border=\"true\"]::after, .framer-3qqCZ [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-3qqCZ.framer-q0781z, .framer-3qqCZ .framer-q0781z { display: block; }", ".framer-3qqCZ.framer-juid59 { height: 347px; overflow: hidden; position: relative; width: 424px; will-change: var(--framer-will-change-override, transform); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 347
 * @framerIntrinsicWidth 424
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 */
const Framerwn2HKXuLC: React.ComponentType<Props> = withCSS(Component, css, "framer-3qqCZ") as typeof Component;
export default Framerwn2HKXuLC;

Framerwn2HKXuLC.displayName = "Cards/Highlight";

Framerwn2HKXuLC.defaultProps = {height: 347, width: 424};

addFonts(Framerwn2HKXuLC, [])